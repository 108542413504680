:root {
  --color-stone-50:     #fafaf9;
  --color-stone-100:    #f5f5f4;
  --color-stone-200:    #e7e5e4;
  --color-stone-300:    #d6d3d1;
  --color-stone-400:    #a8a29e;
  --color-stone-500:    #78716c;
  --color-stone-600:    #57534e;
  --color-stone-700:    #44403c;
  --color-stone-700-80: #292524cc;
  --color-stone-800:    #292524;
  --color-stone-800-40: #29252466;
  --color-stone-900:    #1c1917;
  --color-stone-950:    #0c0a09;
  --color-stone-950-20: #0c0a0933;

  --color-red-50:  #fef2f2;
  --color-red-100: #fee2e2;
  --color-red-200: #fecaca;
  --color-red-300: #fca5a5;
  --color-red-400: #f87171;
  --color-red-500: #ef4444;
  --color-red-600: #dc2626;
  --color-red-700: #b91c1c;
  --color-red-800: #991b1b;
  --color-red-900: #7f1d1d;
  --color-red-950: #450a0a;

  --max-screen-width: 1280px;
  --min-screen-width: 1024px;

  --heading-text: 'Passion One', sans-serif;
  --body-text: 'Open Sans', sans-serif;

  --color-content-bg: #fafaf9;      /* --color-stone-50 */

  --color-primary: #991b1b;         /* --color-red-800 */
  --color-primary-hover: #7f1d1d;   /* --color-red-900 */
  --color-primary-text: #fafaf9;    /* --color-stone-50 */

  --color-secondary: #292524;       /* --color-stone-800 */
  --color-secondary-text: #fafaf9;  /* --color-stone-50 */

  --nav-color-border: #57534e;
  --link-color: #37b;

  --color-link: #37b;
  --color-form-focus: #0284c7;
  --color-shadow: #1c191733;
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;
  --border-radius-xl: 24px;
  --border-radius-2xl: 32px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply max-w-screen-xl mx-auto flex flex-col gap-y-4 items-center justify-around;
  }
}

html, body {
  font-family: Arial, Helvetica, sans-serif;
}

.rounded-image {
  @media screen and ( min-width: 1024px ) {
    border-radius: 1.5rem;
  }
}

.leaflet-container {
  flex: 1;
  height: 300px;
  background-color: var(--color-dark--2);
  position: relative;
  margin-bottom: 1rem;

  @media screen and ( min-width: 1024px ) {
    height: 240px;
    margin-bottom: 0;
    border-bottom: 4px solid var(--color-stone-700);
  } 
}